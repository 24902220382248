import React from 'react';

import { Button } from "@material-ui/core"

import './Button.scss';
import Arrow from "./../../../images/arrow.svg";
import WhiteArrow from './../../../images/white-arrow.svg';

const ButtonWrapper = ({ label, shape, hideArrow, ...props}) => (
  <Button className={"button-wrapper " + props.theme + " " + shape} {...props} >
    <span>{label}</span>
    {!hideArrow && <img src={props.theme === "primary" ? WhiteArrow : Arrow} alt="" />}
  </Button>
);

export default ButtonWrapper;
